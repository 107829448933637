(function($) {
    $(function() {
        // This is needed because of a bug in the version of  jQuery we've used.
        // When we call $(document).trigger('cart.updated'), there's a block of
        // code that checks if 'cart' in document and if so tries to call it as
        // a function. Becase we have many form[name="cart"] on the page,
        // document.cart does exist, but isn't a function, jQuery tries to call
        // it, and bails out. We shouldn't be relying on these shortcut
        // references as they are non-standard so removing it here should
        // suffice. Hopefully there aren't any unforseen issues. DH
        try {
            delete document.cart;
        }
        catch (e) {
            document.cart = [][0];
        }
    });

    // pwp
    var qualified = false;
    var prodPath;
    var prodImage;

    $(document).on('RPC:RESULT', function(event, response, request) {
        var data = JSON.parse(response.responseText),
        pwpData, productData;
        
        if(request.params){
          var params = request.params[0];
        }else{
          return;
        }

        // skip if we've already seen the popup
        /*if (+$.cookie('pwpPopupSeen')) {
            return;
        }*/

        // skip non-cart submits
        if (request.method != 'rpc.form' || params._SUBMIT != 'cart') {
            return;
        }

        // skip if we have no data
        if (!(data || data[0] || data[0].result || data[0].result.data)) {
            return;
        }
        else {
            data = data[0].result.data;
        }

        // skip if we have no order items
        if (
            data.trans_data &&
            data.trans_data.order &&
            data.trans_data.order.items
        ) {
            productData = data.trans_data.order.items;
        }
        else {
            return;
        }

        // skip if we have no pwp data
        if (
            data.trans_data &&
            data.trans_data.pwp
        ) {
            pwpData = data.trans_data.pwp;
        }
        else {
            return;
        }

        // of if pwp isn't active
        if (!pwpData.active) {
            return;
        }

        $.each(productData, function() {
            // save data to vars
            var details = this['sku.PATH'].match(/(CAT\d+)(PROD\d+)(SKU\d+)/),
            catId = details[1],
            prodId = details[2],
            skuId = details[3];

            if (!qualified) {
                $.each(pwpData.qualifiers.cats, function() {
                    if (this == catId && !($.cookie('pwpCatID'+catId))) {
                        qualified = true;
                        prodPath = pwpData.qualifiers.path[catId];
                        prodImage = pwpData.qualifiers.popup_images[catId];

                        $.cookie('pwpCatID'+catId, 1, {
                            path: '/'
                        });
                        return false; // last
                    }
                });
            }

            if (!qualified) {
                $.each(pwpData.qualifiers.prods, function() {
                    if (this == prodId && !($.cookie('pwpProdID'+prodId))) {
                        qualified = true;
                        prodPath = pwpData.qualifiers.path[prodId];
                        prodImage = pwpData.qualifiers.popup_images[prodId];

                        $.cookie('pwpProdID'+prodId, 1, {
                            path: '/'
                        });
                        return false; // last
                    }
                });
            }

            if (!qualified) {
                $.each(pwpData.qualifiers.skus, function() {
                    if (this == skuId) {
                        qualified = true;

                        return false; // last
                    }
                });
            }

            if (qualified) {
                return false;
            }
        });
    });

    $(document).on('addToCart.success', function() {
        if (qualified) {
            generic.template.get({
                path: '/templates/pwp_september2015.tmpl',
                callback: function(html) {
                    var overlayContent = html.replace('PRODIMAGE',prodImage);
                    overlayContent = overlayContent.replace('PRODPATH',prodPath);
                    generic.overlay.launch({
                        content: overlayContent,
                        cssStyle: {
                            width: '590px',
                            height: '375px'
                        }
                    });
                }
            });

            /*$.cookie('pwpPopupSeen', 1, {
                path: '/'
            });*/

            qualified = false;
        }
    });
})(jQuery);
